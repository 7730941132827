import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import UsuarioService from '../../services/Usuario';

import UsuarioActions, { UsuarioTypes } from '../ducks/usuario';
import NotifierActions from '../ducks/notifier';

function* signing({ login, senha }) {
  try {
    const { usuario, accessToken } = yield call([UsuarioService, 'login'], login, senha);
    localStorage.setItem('@alicrim:accessToken', accessToken);
    // const usuario = yield call(UsuarioService.whoami);
    localStorage.setItem('@alicrim:usuario', JSON.stringify(usuario)); 

    yield put(UsuarioActions.signingSuccess());
    yield put(replace('/app'));
  } catch (err) {
    console.log(err);
    let errorMessage = 'Erro inesperado, tente novamente';
    if (err.response && err.response.status === 401) {
      errorMessage = 'Login ou senha inválidos';
    }
    yield put({
      ...UsuarioActions.signingFailure(errorMessage),
      notifier: {
        message: errorMessage,
        options: {
          variant: 'error',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    });
  }
}

function* signout() {
  try{
    yield call([UsuarioService, 'logout']);
    // console.log("sair agora")
    localStorage.removeItem('@alicrim:accessToken');
    localStorage.removeItem('@alicrim:usuario');
    localStorage.removeItem('@alicrim:dashboard');
  }catch(err){
    console.log(err);
    // let errorMessage = 'Erro inesperado, tente novamente';
    if (err.response && err.response.status === 401) {
      // errorMessage = 'Login ou senha inválidos';
    }
  }
  yield put(replace('/login'));
}

function* checkAuth() {
  const token = localStorage.getItem('@alicrim:accessToken');
  const { pathname } = yield select(state => state.router.location);
  if (!token) {
    if(pathname.includes('/questionario/') || pathname.includes('/questionarioclean/') || pathname.includes('/ho/') 
    || pathname.includes('/aulas/') || pathname.includes('/quiz/') 
    || pathname.includes('/alterquiz/') || pathname.includes('/nasa/') 
    || pathname.includes('/alternasa/')
    || pathname.includes('/nasaho/')
    || pathname.includes('/nasalist/') 
    || pathname.includes('/version') 
    || pathname.includes('/conteudo')
    || pathname.includes('/alterlogin') ) {
      return;
    }
    return yield put(replace('/login'));
  }
  yield put(UsuarioActions.signingSuccess());


  if (pathname && pathname !== '/login') {
    return yield put(replace(pathname));
  }

  return yield put(replace('/app'));
}

function* fetchUsuarios() {
  try {
    const usuarios = yield call([UsuarioService, 'getAll']);

    yield put(UsuarioActions.fetchUsuariosSuccess(usuarios));
  } catch (err) {
    console.log(err);
    yield put(NotifierActions.notifyError('Não foi possível carregar os usuários do sistema'));
  }
}

export default function* root() {
  yield takeLatest(UsuarioTypes.SIGNING, signing);
  yield takeLatest(UsuarioTypes.SIGNOUT, signout);

  yield takeLatest(UsuarioTypes.FETCH_USUARIOS, fetchUsuarios);

  yield call(checkAuth);
}
