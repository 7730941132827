import React, { lazy, Suspense } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import CustomRoute from './CustomRoute';

import PrivateRoute from './PrivateRoute';
import Notifier from '../components/Notifier';
import ErrorBoundary from '../components/ErrorBoundary';

import Login from '../pages/Login';
import Loading from '../pages/Application/Loading';
import Questionario from '../pages/Questionario'
import QuestionarioNotNasa from '../pages/QuestionarioNotNasa'
import QuestionarioInterno from '../pages/QuestionarioInterno'
import QuestionarioNasa from '../pages/QuestionarioNasa'
import Quiz from '../pages/Quiz'
import AlterUser from '../pages/AlterUser'
import ModalPesquisaInterno from '../pages/Application/Dashboard/visaogeral/components/ModalPesquisaInterno'
import Aulas from '../pages/Aulas'
import Version from '../pages/TimeVersion'

const Application = lazy(() => import('../pages/Application'));

const Routes = () => (
  <>
    <Notifier />
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Switch>
          <CustomRoute path="/ho/:id?" component={Questionario} routeTitle="Questionario"/>
          <CustomRoute path="/questionario/:apiKey?" component={Questionario} routeTitle="Questionario"/>
          <CustomRoute path="/questionarioclean/:apiKey?" component={QuestionarioNotNasa} routeTitle="QuestionarioNotNasa"/>
          <CustomRoute path="/alterquiz/:id?" component={QuestionarioInterno} routeTitle="QuestionarioInterno"/>
          <CustomRoute path="/quiz/:apiKey?" component={QuestionarioInterno} routeTitle="QuestionarioInterno"/>
          <CustomRoute path="/alternasa/:id?" component={QuestionarioNasa} routeTitle="QuestionarioNasa"/>
          <CustomRoute path="/nasa/:apiKey/:userId?" component={QuestionarioNasa} routeTitle="QuestionarioNasa"/>
          <CustomRoute path="/nasaho/:id?" component={QuestionarioNasa} routeTitle="QuestionarioNasa"/>
          <CustomRoute path="/nasalist/:apiKey?" component={ModalPesquisaInterno} routeTitle="ModalPesquisaInterno"/>
          <CustomRoute path="/aulas/:apiKey?" component={Aulas} routeTitle="Aulas"/>
          <CustomRoute path="/conteudo/:apiKey?" component={Quiz} routeTitle="Quiz"/>
          <CustomRoute path="/version" component={Version} routeTitle="Version"/>
          <CustomRoute path="/login" component={Login} routeTitle="Login" />
          <CustomRoute path="/alterlogin/:login?" component={AlterUser} routeTitle="AlterUser"/>
          <PrivateRoute path="/app" component={Application} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  </>
);

export default Routes;
